.content {
    text-align: center;
    margin-bottom: 30px;
  }
  .header {
    font-size: 30px;
  }
  
  .img-body img {
    display: flex;
    margin: 0 auto;
    height: 40vh;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  
  .img-body {
    width: 200px;
    aspect-ratio: 1/1;
    outline-color: red;
    display: inline-block;
  }
  
  .img-cut img {
    width: 100%;
  }

  .shadow {
    color: #164176;
    font-size: 3.1rem !important;
    text-align: center;
    font-weight: 400;
    position: relative;    
    letter-spacing: -2px !important;
}
   
/* 
  .shadow::after {
    content: attr(data-content);
    position: relative;
    top: -45px;
    left: 0;
    z-index: -1;
    color: rgba(40, 95, 145, 0.08);
    font-size: 4.5rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 400;
    display: block;
    transform: translateY(-50%);
  } */

  .contentProduct {
    display: flex;
  }

  .product-info , .product-image {
    /* margin-top: 35px; */
    width: 50%;
}
  @media only screen and (max-width: 915px) {
    .contentProduct {
        display: block;
        justify-content: center;
    }

    .product-info , .product-image {
        margin-top: 35px;
        width: 100%;
    }

    .title, .subtitle {
        font-size: 30px !important;
    }
  }


  /* ////------------------------------------------------ */
  

  .cardColor{
    width: 22%;
}

.cardContainer{
    width: 68%;
    margin-left: 100px;
    padding-top: 30px;
    padding-left: 15px;
    justify-content: right;
    align-items: end;
    text-align: end;
}


.imageColor {
  width: 46px !important;
}

.thumbnailContainer {
  padding-top: 95px;
}

.thumbnailImage {
   width: 130px;
   height: auto ; 
   margin: 5px;
   cursor: pointer; 
   border: solid 1px #d7d0d0; 
    border-radius: 10%;
}