@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Quicksand:wght@300;400&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Quicksand:wght@300;400&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Quicksand:wght@300;400&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Quicksand:wght@300;400&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:wght@700&family=Kanit:wght@700&family=Roboto+Mono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:wght@700&family=Kanit:wght@700&family=Roboto+Mono&family=Rubik:wght@300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+Tirhuta&display=swap);
.slider .slider-container {
  position: relative;
}
.slider .slider-container-text {
  color: #ffffff;
  color: white;
  position: absolute;
  bottom: 70%;
  left: 15%;
  max-width: 85%;

/* From https://css.glass */
background: rgba(255, 255, 255, 0);
/* border-radius: 16px; */
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(15.3px);
-webkit-backdrop-filter: blur(15.3px);
border: 0px solid rgba(255, 255, 255, 0.48);
}
@media (min-width: 992px) {
  .slider .slider-container-text {
    max-width: 70%;
  }
}
.slider .slider-container-text-title {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 25px;
}
@media (min-width: 768px) {
  .slider .slider-container-text-title {
    font-size: 30px;
  }
}
@media (min-width: 992px) {
  .slider .slider-container-text-title {
    font-size: 38px;
  }
}
.slider .slider-container-text-subtitle {
  font-weight: 300;
  font-size: 20px;
}
@media (min-width: 768px) {
  .slider .slider-container-text-subtitle {
    font-size: 25px;
  }
}
@media (min-width: 992px) {
  .slider .slider-container-text-subtitle {
    font-size: 26px;
  }
}
.slider .slider-swiper {
  height: 65vh;
}
.slider .slider-swiper-media {
  object-fit: cover;
  width: 100%;
  height: 65vh;
}
.slider .slider-swiper-arrow {
  position: absolute;
  bottom: 5%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.slider .slider-swiper-arrow span {
  display: block;
  width: 1.688rem;
  height: 1.688rem;
  border-bottom: 0.188rem solid #ffffff;
  border-right: 0.188rem solid #ffffff;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  margin: -0.625rem;
  -webkit-animation: animate-arrow 2s infinite;
          animation: animate-arrow 2s infinite;
}
.slider .slider-swiper-arrow span:nth-child(2) {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.slider .slider-swiper-arrow span:nth-child(3) {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}/*# sourceMappingURL=Slider.css.map */
.phone-number-form-container input {
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #000000;
  color: black;
}
.phone-number-form-container input::-webkit-input-placeholder {
  color: #000000;
  color: rgba(0, 0, 0, 0.51);
}
.phone-number-form-container input::placeholder {
  color: #000000;
  color: rgba(0, 0, 0, 0.51);
}

.phone-number-form-input {
  width: 100% !important;
  border-radius: 0 !important;
}

.phone-number-form-invalid {
  font-size: 0.8rem;
}

.phone-number-form-sm {
  height: calc(1.5em + 0.5rem + 2px) !important;
  padding-top: 0.25rem !important;
  padding-left: 2.4rem !important;
  padding-bottom: 0.25rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  border-radius: 0 !important;
}

.dropdown-button-class .selected-flag {
  cursor: auto !important;
}/*# sourceMappingURL=PhoneNumberForm.css.map */
@-webkit-keyframes img-show-up {
  0% {
    right: 0;
  }
  100% {
    right: 100%;
  }
}
@keyframes img-show-up {
  0% {
    right: 0;
  }
  100% {
    right: 100%;
  }
}
@-webkit-keyframes phone-ripple-effect {
  0% {
    box-shadow: 0 0 0 0 #ffffff 50;
  }
  100% {
    box-shadow: 0 0 0 1.25rem rgba(228, 149, 152, 0);
  }
}
@keyframes phone-ripple-effect {
  0% {
    box-shadow: 0 0 0 0 #ffffff 50;
  }
  100% {
    box-shadow: 0 0 0 1.25rem rgba(228, 149, 152, 0);
  }
}
@-webkit-keyframes phone-open {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes phone-open {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes phone-close {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}
@keyframes phone-close {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}
@-webkit-keyframes animate-arrow {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(-1.25rem, -1.25rem);
            transform: rotate(45deg) translate(-1.25rem, -1.25rem);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(1.25rem, 1.25rem);
            transform: rotate(45deg) translate(1.25rem, 1.25rem);
  }
}
@keyframes animate-arrow {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(-1.25rem, -1.25rem);
            transform: rotate(45deg) translate(-1.25rem, -1.25rem);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(1.25rem, 1.25rem);
            transform: rotate(45deg) translate(1.25rem, 1.25rem);
  }
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.contact-phone {
  position: fixed;
  display: flex;
  height: 80px;
  right: 40px;
  bottom: 20px;
  z-index: 1000;
  border-radius: 50%;
  padding: 20px;
  transition: border-radius 0.2s ease-in, padding 0.2s ease;
  border: 3px solid #ffffff 50;
  background-color: #ca2b30;
  background-color: #ca2b30;
}
.contact-phone.animation {
  -webkit-animation: phone-ripple-effect 6s infinite;
          animation: phone-ripple-effect 6s infinite;
}
.contact-phone.open {
  padding-left: 50px;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 200px;
}
.contact-phone .contact-phone-form {
  display: inline-block;
  width: auto;
  max-width: 0;
  overflow: hidden;
  transition: max-width 0.2s ease-out;
}
.contact-phone .contact-phone-form label {
  font-weight: 300;
  color: #ffffff;
  color: white;
}
.contact-phone .contact-phone-form-submit {
  padding: 0.313rem 2.5rem !important;
  border-radius: 0;
  background-color: #307cbe;
  background-color: #307cbe;
}
.contact-phone .contact-phone-form-submit:hover, .contact-phone .contact-phone-form-submit:active, .contact-phone .contact-phone-form-submit:focus {
  border-color: #307cbe;
  color: #307cbe !important;
  color: #307cbe !important;
  background-color: #ffffff !important;
  background-color: white !important;
}
.contact-phone .contact-phone-form-submit:focus {
  box-shadow: none !important;
}
.contact-phone .contact-phone-submit-success {
  display: inline-block;
  width: auto;
  max-width: 0;
  overflow: hidden;
  transition: max-width 0.2s ease-out;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 300;
  color: #ffffff;
  color: white;
}
.contact-phone .contact-phone-form.visible {
  max-width: 900px;
}
.contact-phone .contact-phone-submit-success.visible {
  max-width: 1400px;
  padding-left: 100px;
  padding-right: 100px;
}
.contact-phone .contact-phone-icon {
  font-size: 33px;
  cursor: pointer;
  color: #ffffff;
  color: white;
}/*# sourceMappingURL=ContactPhoneForm.css.map */
.gamma-item .gamma-item-wrapper {
  position: relative;
}
.gamma-item .gamma-item-wrapper-effect:before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000000;
  background: linear-gradient(to right, #000000 55, #000000 55);
  opacity: 0;
  transition: opacity 0.4s linear;
}
.gamma-item .gamma-item-wrapper-effect:hover:before {
  opacity: 1;
}
.gamma-item .gamma-item-wrapper-media {
  position: relative;
  z-index: -1;
  display: block;
  object-fit: cover;
  width: 100%;
  height: 400px;
}
.gamma-item .gamma-item-wrapper-title {
  text-align: left;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  height: 100px;
  padding-left: 15px;
  color: #ffffff;
  color: white;
}
.gamma-item .gamma-item-buttons-button {
  padding: 2px;
  font-size: 13px;
  text-transform: inherit;
  border-color: #d6d6d6;
  width: 100%;
  color: #000000;
  color: black;
  background-color: #d6d6d6;
  background-color: #d6d6d6;
}
.gamma-item .gamma-item-buttons-button:hover, .gamma-item .gamma-item-buttons-button:active {
  color: #000000;
  color: black;
  background-color: #ffffff;
  background-color: white;
}/*# sourceMappingURL=GammaItem.css.map */

.gamma {
  min-height: 380px;
}
.gamma .gamma-wrapper-col {
  margin-bottom: 3rem;
}/*# sourceMappingURL=Gamma.css.map */

/* #menu {
  display: flex;
  width: 90%;
  margin: 0 auto;
  height: auto;
  overflow: hidden;
  text-align: -webkit-center;
  padding-bottom: 2em;
  padding-top: 2em;
} */
.filter-label {
  text-transform: uppercase;
  font-size: 0.7rem;
  color: #000000;
  color: rgba(0, 0, 0, 0.76);
}

select.form-control.filter-select {
  text-transform: uppercase;
  font-size: 14px;
}/*# sourceMappingURL=Filter.css.map */
.gallery-item {
  border: none !important;
}
.gallery-item .gallery-item-body {
  padding: 0;
}
.gallery-item .gallery-item-subtitle {
  padding-top: 0.313rem;
  height: 1.75rem;
  font-size: 0.688rem;
  color: #000000;
  color: black;
}
.gallery-item .gallery-item-title {
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 0;
  color: #ff2a2a;
  color: #ff2a2a;
}
.gallery-item .gallery-item-type,
.gallery-item .gallery-item-title {
  text-transform: uppercase;
}
.gallery-item .gallery-item-divider {
  border-top: 0.125rem solid #000000;
  margin: 0.344rem 0 0.906rem 10px;
  width: 0;
  transition: width 0.5s ease-out;
  width: 8.25rem;
}
@media (min-width: 768px) {
  .gallery-item .gallery-item-divider {
    width: 0;
  }
}
.gallery-item .gallery-item-divider-hover {
  width: 8.25rem;
}
.gallery-item .gallery-item-description * {
  font-size: 0.813rem;
  text-align: justify;
  color: #000000;
  color: rgba(0, 0, 0, 0.48);
}
.gallery-item .gallery-item-description-clamping {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-line-clamp: var(--line-clamp, 3);
  -webkit-box-orient: vertical;
  word-break: "none";
  word-break: var(--word-break, "none");
  overflow: hidden;
}/*# sourceMappingURL=GalleryItem.css.map */
.pages {
  border-top: 0.063rem;
  border-top-style: solid;
  border-top-color: #00000050;
  margin-top: 4.375rem;
  border-radius: 0 !important;
  padding-top: 2.5rem;
}
.pages .pages-wrapper-input::-webkit-outer-spin-button, .pages .pages-wrapper-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.pages .pages-wrapper-input[type=number] {
  -moz-appearance: textfield;
  width: 4.375rem;
}
.pages .pages-wrapper-button {
  text-align: center;
}
.pages .pages-wrapper-button button {
  padding-left: 6.25rem;
  padding-right: 6.25rem;
  border-color: #426fad;
  color: #426fad;
  color: #426fad;
}
.pages .pages-wrapper-button button:hover, .pages .pages-wrapper-button button:active {
  color: #ffffff;
  color: white;
  background-color: #426fad !important;
  background-color: #426fad !important;
}
.pages .pages-wrapper-button button:focus {
  box-shadow: none !important;
}
.pages .pages-wrapper-total-pages {
  padding-left: 0;
}
.pages .pages-wrapper-icons {
  padding-left: 0.313rem;
  padding-right: 0.313rem;
}
.pages .pages-wrapper-icons * {
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.pages .pages-wrapper-icons *:hover {
  color: #426fad;
  color: #426fad;
}/*# sourceMappingURL=Pagination.css.map */
.mobile-divider {
  display: block;
}
.mobile-divider:last-child {
  display: none;
}
.mobile-divider hr {
  border-top: 2px solid #000000 30;
}
@media (min-width: 768px) {
  .mobile-divider {
    display: none;
  }
}/*# sourceMappingURL=MobileDivider.css.map */
.gallery .gallery-wrapper-header {
  display: flex;
  margin-bottom: 15px;
  justify-content: center;
}
.gallery .gallery-wrapper-header-row {
  transition: all 0.2s ease-in-out;
  background-color: #ff2a2a;
  background-color: #ff2a2a;
  height: 100%;
  width: 100%;
  padding: 10px;
}
@media (min-width: 768px) {
  .gallery .gallery-wrapper-header-row {
    height: 88%;
    width: 100%;
    padding-top: 20px;
    padding-left: 5px;
  }
}
@media (min-width: 992px) {
  .gallery .gallery-wrapper-header-row {
    height: 77%;
    width: 88%;
    padding-top: 24px;
    padding-left: 24px;
  }
}
.gallery .gallery-wrapper-header-row:hover {
  -webkit-transform: none;
          transform: none;
}
@media (min-width: 768px) {
  .gallery .gallery-wrapper-header-row:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}
.gallery .gallery-wrapper-header-row-col {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.gallery .gallery-wrapper-header-row-col-title {
  font-weight: 700;
  color: #ffffff;
  color: white;
}
.gallery .gallery-wrapper-header-row-col-title * {
  font-size: 21px;
}
.gallery .gallery-wrapper-header-row-col-all {
  font-weight: 500;
  color: #ffffff;
  color: white;
  display: none;
}
.gallery .gallery-wrapper-header-row-col-all * {
  font-size: 14px;
}
@media (min-width: 768px) {
  .gallery .gallery-wrapper-header-row-col-all {
    display: flex;
  }
}
.gallery .gallery-wrapper-header-row-col-title, .gallery .gallery-wrapper-header-row-col-all {
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .gallery .gallery-wrapper-header {
    margin-bottom: 0;
    justify-content: flex-start;
  }
}
.gallery .gallery-wrapper-items-item {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .gallery .gallery-wrapper-items-item {
    margin-bottom: 3rem;
  }
}

.gallery-border {
  border-top: 2px solid #000000 19;
}/*# sourceMappingURL=Gallery.css.map */
.error .error-code {
  font-size: 21.875rem;
  font-weight: 700;
  color: #e6ecf4;
  color: rgba(230, 236, 244, 0.76);
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: -1;
  text-align: center;
}
.error .error-image {
  margin-top: 11.25rem;
}

.return-button {
  border-color: #5e5e5e !important;
  background-color: #5e5e5e !important;
  background-color: #5e5e5e !important;
}/*# sourceMappingURL=Error.css.map */
.maintitle {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  overflow: hidden;
}
.maintitle .maintitle-video {
  z-index: 1;
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.maintitle .maintitle-wrapper {
  z-index: 2;
}
.maintitle .maintitle-wrapper-title {
  text-transform: uppercase;
  text-align: center;
  display: table;
  font-weight: 500;
  margin-top: 10%;
  color: #ffffff;
  color: white;
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.77);
  padding: 0.5rem 1rem;
  font-size: 30px;
}
@media (min-width: 768px) {
  .maintitle .maintitle-wrapper-title {
    padding: 0.5rem 4.063rem;
    font-size: 40px;
  }
}
.maintitle .maintitle-wrapper-spontaneous-application-col {
  margin-right: 0;
  text-align: center;
}
@media (min-width: 768px) {
  .maintitle .maintitle-wrapper-spontaneous-application-col {
    margin-right: 5rem;
    text-align: left;
  }
}
.maintitle .maintitle-wrapper-job-offers-col {
  margin-top: 30px;
  text-align: center;
}
@media (min-width: 768px) {
  .maintitle .maintitle-wrapper-job-offers-col {
    margin-top: 0;
    text-align: left;
  }
}
.maintitle .maintitle-wrapper-recruitment-button {
  font-size: 1rem;
  background-color: #1109ff;
  background-color: #1109ff;
  padding: 0.313rem 0 !important;
  width: 55%;
}
@media (min-width: 768px) {
  .maintitle .maintitle-wrapper-recruitment-button {
    padding: 0.313rem 30px !important;
    width: auto;
  }
}
.maintitle .maintitle-wrapper-recruitment-button:hover, .maintitle .maintitle-wrapper-recruitment-button:active {
  background-color: #ffffff !important;
  background-color: white !important;
  color: #1109ff !important;
  color: #1109ff !important;
}/*# sourceMappingURL=MainTitle.css.map */
.breadcrumb {
  background-color: unset !important;
}
.breadcrumb li.breadcrumb-item {
  text-transform: uppercase;
  font-size: 13px;
}
.breadcrumb li.breadcrumb-item:first-child a {
  color: #426fad !important;
  color: #426fad !important;
}
.breadcrumb li.breadcrumb-item:nth-child(2)::before {
  color: #535353 !important;
  color: #535353 !important;
}
.breadcrumb li.breadcrumb-item:first-child a, .breadcrumb li.breadcrumb-item:nth-child(2)::before {
  font-weight: 700;
}
.breadcrumb li.breadcrumb-item a, .breadcrumb li.breadcrumb-item::before {
  color: #535353 !important;
  color: rgba(83, 83, 83, 0.28) !important;
}
.breadcrumb li.breadcrumb-item.active {
  color: #535353 !important;
  color: #535353 !important;
}
@media (min-width: 576px) {
  .breadcrumb li.breadcrumb-item {
    font-size: 16px;
  }
}/*# sourceMappingURL=BreadcrumbPath.css.map */
.title-subtitle {
  font-size: 1.25rem;
  color: #000000;
  color: rgba(0, 0, 0, 0.28);
}

.title-title {
  font-size: 1.7rem;
  font-weight: 500;
  color: #000000;
  color: rgba(0, 0, 0, 0.76);
  text-align: left;
}

.title-date {
  font-size: 0.75rem;
  color: #ff2a2a;
  color: #ff2a2a;
}

.title-title,
.title-subtitle {
  text-transform: uppercase;
  text-align: left;
}

.title-title,
.title-subtitle,
.title-date {
  line-height: 30px;
}

.title-title-contact {
  font-weight: 700;
  font-size: 1.563rem;
  text-transform: uppercase;
}

.title-subtitle-contact {
  font-weight: 500;
  text-align: left;
  font-size: 1.3rem;
}

.title-title-privacy-policy {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 1.3rem;
}/*# sourceMappingURL=Title.css.map */
.social-media-collection {
  width: 1.875rem;
  height: 1.875rem;
}/*# sourceMappingURL=SocialMediaCollection.css.map */
.social-media-title {
  margin: 0;
  padding: 0;
}

.social-media-primary .social-media-primary-title {
  color: #ffffff;
  color: #000000;
}

.social-media-primary a,
.social-media-secondary a {
  margin-right: 0.625rem;
}

.social-media-terciary {
  margin-top: 1.25rem;
}
.social-media-terciary .social-media-terciary-title {
  color: #b7b7b7;
  color: #b7b7b7;
}
.social-media-terciary a {
  margin-right: 1.875rem;
}

.social-media-primary-title,
.social-media-secondary-title,
.social-media-terciary-title {
  font-size: 0.7rem;
}
.social-media-primary div a:last-child,
.social-media-secondary div a:last-child,
.social-media-terciary div a:last-child {
  margin-right: 0;
}

.social-media-bg-light,
.social-media-light {
  fill: #ffffff;
}

.social-media-bg-dark {
  fill: #000000;
}

.social-media-bg-gray {
  fill: #b7b7b7;
}

.social-media-bg-dark-gray {
  fill: #53555a;
}

.social-media-dark-blue {
  fill: #313957;
}/*# sourceMappingURL=SocialMedia.css.map */
.description-gallery {
  text-transform: uppercase;
  font-size: 1.563rem;
  color: #000000;
  color: rgba(0, 0, 0, 0.76);
}

.description-section {
  color: #000000;
  color: black;
  text-align: justify;
}

.description-download-area {
  color: #000000;
  color: rgba(0, 0, 0, 0.48);
}

.description-contact {
  color: #5b5b5b;
  color: #5b5b5b;
  text-align: left;
  justify-content: left;
}
.description-contact a, .description-contact a:hover {
  color: revert !important;
}

.decription-privacy-policy {
  color: #000000;
  color: black;
}/*# sourceMappingURL=Description.css.map */
.certificate .certificate-text {
  font-size: 0.8rem;
  text-transform: uppercase;
}
.certificate .certificate-media-col {
  text-align: center;
}
@media (min-width: 768px) {
  .certificate .certificate-media-col {
    text-align: left;
  }
}
.certificate .certificate-text,
.certificate .certificate-media {
  cursor: pointer;
}/*# sourceMappingURL=Certificate.css.map */
.group .group-social-media-desktop {
  display: none;
}
@media (min-width: 768px) {
  .group .group-social-media-desktop {
    display: flex;
  }
}
.group .group-social-media-mobile {
  display: flex;
}
@media (min-width: 768px) {
  .group .group-social-media-mobile {
    display: none;
  }
}
.group .group-body {
  margin-top: 0;
}
@media (min-width: 768px) {
  .group .group-body {
    margin-top: 5rem;
  }
}
.group .group-media-row {
  margin-top: 40px;
}
@media (min-width: 768px) {
  .group .group-media-row {
    margin-top: 0;
  }
}
.group .group-media-row-col {
  margin-bottom: 0.625rem;
}
.group .group-media-row-col-media {
  cursor: pointer;
}/*# sourceMappingURL=Group.css.map */
/*# sourceMappingURL=SpontaneousApplication.css.map */
.joboffer {
  width: 85%;
}
.joboffer .joboffer-media {
  object-fit: cover;
  width: 100%;
  height: 17rem;
}
.joboffer .joboffer-wrapper {
  position: relative;
}
.joboffer .joboffer-wrapper-title {
  font-size: 16px;
  font-weight: 500;
  color: #535353;
  color: #535353;
}
.joboffer .joboffer-wrapper-date {
  font-size: 0.75rem;
}
.joboffer .joboffer-wrapper-type {
  font-size: 0.8rem;
  font-weight: 700;
  color: #ff2a2a;
  color: #ff2a2a;
}
.joboffer .joboffer-wrapper-location {
  font-size: 0.8rem;
}
.joboffer .joboffer-wrapper-title, .joboffer .joboffer-wrapper-type, .joboffer .joboffer-wrapper-location {
  text-transform: uppercase;
}
.joboffer .joboffer-wrapper-button {
  padding: 8px 37px !important;
  background-color: #426fad;
  background-color: #426fad;
}
.joboffer .joboffer-wrapper-button:hover, .joboffer .joboffer-wrapper-button:active {
  color: #426fad;
  color: #426fad;
  background-color: #ffffff !important;
  background-color: white !important;
}/*# sourceMappingURL=JobOffer.css.map */
.joboffer-wrapper .joboffer-wrapper-col {
  margin-bottom: 3rem;
}
.joboffer-wrapper .joboffer-wrapper-col:nth-child(2n) {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}/*# sourceMappingURL=JobOfferCollection.css.map */
.joboffer-maintitle {
  display: block;
}
@media (min-width: 768px) {
  .joboffer-maintitle {
    display: none;
  }
}

.joboffer {
  text-align: left;
}
.joboffer .joboffer-media {
  display: none;
}
@media (min-width: 768px) {
  .joboffer .joboffer-media {
    display: flex;
  }
}
.joboffer .joboffer-title {
  font-weight: 500;
  color: #535353;
  color: #535353;
  font-size: 1.2rem;
}
@media (min-width: 768px) {
  .joboffer .joboffer-title {
    font-size: 1rem;
  }
}
.joboffer .joboffer-divider {
  display: flex;
}
.joboffer .joboffer-divider hr {
  border-top: 0.125rem solid #000000;
  margin: 0;
  width: 8.25rem;
}
@media (min-width: 768px) {
  .joboffer .joboffer-divider {
    display: none;
  }
}
.joboffer .joboffer-date {
  font-size: 0.9rem;
}
@media (min-width: 768px) {
  .joboffer .joboffer-date {
    font-size: 0.75rem;
  }
}
.joboffer .joboffer-type {
  font-weight: 700;
  color: #ff2a2a;
  color: #ff2a2a;
  font-size: 1.1rem;
}
@media (min-width: 768px) {
  .joboffer .joboffer-type {
    font-size: 0.85rem;
  }
}
.joboffer .joboffer-company {
  font-weight: 500;
  color: #535353;
  color: #535353;
  font-size: 1.08rem;
}
@media (min-width: 768px) {
  .joboffer .joboffer-company {
    font-size: 0.83rem;
  }
}
.joboffer .joboffer-location {
  font-size: 1rem;
}
@media (min-width: 768px) {
  .joboffer .joboffer-location {
    font-size: 0.8rem;
  }
}
.joboffer .joboffer-title,
.joboffer .joboffer-type,
.joboffer .joboffer-location {
  text-transform: uppercase;
}
.joboffer .joboffer-disclaimer {
  border-top: 0.063rem;
  border-top-style: solid;
  border-top-color: #00000050;
  margin-top: 4.375rem;
  border-radius: 0 !important;
  padding-top: 2.5rem;
}/*# sourceMappingURL=JobOffer.css.map */
.category-item {
  border: none !important;
}
.category-item .category-item-background {
  padding-top: 35px;
  padding-bottom: 35px;
  background-color: #ffffff;
}
.category-item .category-item-primary-media {
  transition: all 0.4s linear;
}
.category-item .category-item-secondary-media {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.5s ease-out;
}
.category-item .category-item-secondary-media:hover {
  opacity: 1;
}
.category-item .category-item-primary-media,
.category-item .category-item-secondary-media {
  border-radius: 0 !important;
}
.category-item .category-item-energy-efficiency-media {
  position: absolute;
  width: 40px;
  height: 20px;
  left: 0;
  bottom: 10px;
}
.category-item .category-item-body-title {
  font-size: 15px;
  font-weight: 700;
}
.category-item .category-item-body-text {
  text-align: justify;
  font-size: 15px;
  color: #686868;
  color: #686868;
}
.category-item .category-item-body-text-clamping {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-line-clamp: var(--line-clamp, 3);
  -webkit-box-orient: vertical;
  word-break: "none";
  word-break: var(--word-break, "none");
  overflow: hidden;
}
/* .category-item .category-item-border-bottom {
  border-top: 1px solid #000000;
} */
/*# sourceMappingURL=CategoryItem.css.map */
/* 
@media (max-width: 1300px)
{
    img {width: 100% !important;}
} */
.category .category-wrapper-col {
  margin-bottom: 5.7rem;
}
.category .category-wrapper-no-content {
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
}/*# sourceMappingURL=Category.css.map */

.category { width: 1700px;}


/* // Small devices (landscape phones, 576px and up) */
/* @media (min-width: 576px) { ... } */

/* // Medium devices (tablets, 768px and up) */
/* @media (min-width: 768px) { ... } */

/* // Large devices (desktops, 992px and up) */
/* @media (min-width: 992px) { ... } */

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1500px) {

  .category-wrapper-col {
    max-width: 100% !important ;
  }
 }

 .category-wrapper { justify-content: center;}
.categoryCommercial-item {
  border: none !important;
}
.categoryCommercial-item .categoryCommercial-item-background {
  padding-top: 35px;
  padding-bottom: 35px;
  background-color: #ffffff;
}
.categoryCommercial-item .categoryCommercial-item-primary-media {
  transition: all 0.4s linear;
}
.categoryCommercial-item .categoryCommercial-item-secondary-media {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 0.5s ease-out;
}
.categoryCommercial-item .categoryCommercial-item-secondary-media:hover {
  opacity: 1;
}
.categoryCommercial-item .categoryCommercial-item-primary-media,
.categoryCommercial-item .categoryCommercial-item-secondary-media {
  border-radius: 0 !important;
}
.categoryCommercial-item .categoryCommercial-item-energy-efficiency-media {
  position: absolute;
  width: 40px;
  height: 20px;
  left: 0;
  bottom: 10px;
}
.categoryCommercial-item .categoryCommercial-item-body-title {
  font-size: 15px;
  font-weight: 700;
}
.categoryCommercial-item .categoryCommercial-item-body-text {
  text-align: justify;
  font-size: 15px;
  color: #686868;
  color: #686868;
}
.categoryCommercial-item .categoryCommercial-item-body-text-clamping {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-line-clamp: var(--line-clamp, 3);
  -webkit-box-orient: vertical;
  word-break: "none";
  word-break: var(--word-break, "none");
  overflow: hidden;
}
/* .categoryCommercial-item .categoryCommercial-item-border-bottom {
  border-top: 1px solid #000000;
} */
/*# sourceMappingURL=CategoryCommercialItem.css.map */
.family {
  padding-top: 3.8rem;
}
.family .family-subfamilies-col {
  margin-bottom: 3rem;
}
.family .family-subfamilies-col:nth-child(3n-1) {
  display: flex;
  align-items: center;
  justify-content: center;
}
.family .family-subfamilies-col:nth-child(3n) {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.family .family-subfamilies {
  width: 100% !important;
}
@media (min-width: 768px) {
  .family .family-subfamilies {
    width: 630px !important;
  }
}
@media (min-width: 992px) {
  .family .family-subfamilies {
    width: 980px !important;
  }
}
@media (min-width: 1200px) {
  .family .family-subfamilies {
    width: 1300px !important;
  }
}

.family-breadcrumb {
  max-width: 100% !important;
  max-width: 100% !important;
}
@media (min-width: 768px) {
  .family-breadcrumb {
    max-width: 100% !important;
  }
}
@media (min-width: 992px) {
  .family-breadcrumb {
    max-width: 700px !important;
  }
}
@media (min-width: 1200px) {
  .family-breadcrumb {
    max-width: 800px !important;
  }
}/*# sourceMappingURL=Family.css.map */

.overLay:hover {
  -webkit-transform: scale(1.09);
          transform: scale(1.09);
}
.mobile-family-subfamilies-col:nth-child(2n-1) {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-family-subfamilies-col:nth-child(2n) {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 576px) {
  .mobile-family-subfamilies-col:nth-child(2n) {
    display: flex;
    /* align-items: flex-end;
    justify-content: flex-end; */
  }
}/*# sourceMappingURL=MobileFamily.css.map */
.multicarousel-item {
  background-color: #f5f5f5;
}
.multicarousel-item .multicarousel-item-media {
  transition: all 0.4s linear;
}
.multicarousel-item .multicarousel-item-body {
  font-weight: 500;
}
.multicarousel-item .multicarousel-item-body-subtitle {
  font-size: 14px;
  text-align: left;
  color: #ff2a2a;
  color: #ff2a2a;
}
.multicarousel-item .multicarousel-item-body-title {
  font-size: 16px;
  text-align: left;
  color: #000000;
  color: black;
}/*# sourceMappingURL=MultiCarouselItem.css.map */
.multicarousel {
  background: linear-gradient(#a0c6e6 60%, transparent 40%) no-repeat;
  padding-top: 60px;
  padding-bottom: 60px;
}
.multicarousel .multicarousel-wrapper-heading {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 700;
  color: #000000;
  color: black;
}
.multicarousel .multicarousel-wrapper-item {
  border-right: 1px solid #000000;
}

.swiper-button-prev {
  background-image: url(/static/media/prev_arrow_icon.e5c5bcc0.svg);
}

.swiper-button-next {
  background-image: url(/static/media/next_arrow_icon.0670c75e.svg);
}

.swiper-button-prev,
.swiper-button-next {
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: center;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0.66);
}

.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
}/*# sourceMappingURL=MultiCarousel.css.map */
.document-item-description {
  text-transform: uppercase;
  font-size: 1.125rem;
  color: #535353;
  color: #535353;
}

.document-item-button {
  text-transform: inherit !important;
  padding: 0.313rem 0 !important;
  width: 100%;
}

.document-item-button-list {
  border-color: #535353 50 !important;
  color: #426fad !important;
  color: #426fad !important;
}
.document-item-button-list:hover, .document-item-button-list:active {
  background-color: #426fad !important;
  background-color: #426fad !important;
  color: #ffffff !important;
  color: white !important;
}

.document-item-icon-list {
  border: 0.125rem solid #426fad;
  border-radius: 50%;
}

.document-item-button-list:hover .document-item-icon-list {
  border-color: #ffffff;
}

.document-item-button-list:focus,
.document-item-button-view:focus,
.document-item-button-download:focus {
  box-shadow: none !important;
}

.document-item-button-view {
  border-color: #535353 !important;
  background-color: #535353 !important;
  background-color: rgba(83, 83, 83, 0.6) !important;
  color: #ffffff !important;
  color: white !important;
}

.document-item-button-view:hover,
.document-item-button-view:active {
  color: #535353 !important;
  color: rgba(83, 83, 83, 0.6) !important;
  background-color: #ffffff !important;
  background-color: white !important;
}

.document-item-button-download {
  border-color: #ff2a2a !important;
  background-color: #ff2a2a !important;
  background-color: #ff2a2a !important;
  color: #ffffff !important;
  color: white !important;
}
.document-item-button-download:hover, .document-item-button-download:active {
  background-color: #ffffff !important;
  background-color: white !important;
  color: #ff2a2a !important;
  color: #ff2a2a !important;
}/*# sourceMappingURL=DocumentItem.css.map */
.document-search-collection .document-search-collection-document-type {
  font-weight: 500;
  font-size: 1.563rem;
  border: none;
}
.document-search-collection .document-search-collection-line {
  font-size: 1.125rem;
}
.document-search-collection .document-search-collection-family-col {
  margin-top: 1rem;
  margin-bottom: 2rem;
}/*# sourceMappingURL=DocumentSearchCollection.css.map */
.download-area {
  padding-bottom: 9.375rem;
}
.download-area .download-area-header {
  padding: 0.625rem;
  text-transform: uppercase;
  background-color: #ff2a2a;
  background-color: #ff2a2a;
  color: #ffffff;
  color: white;
}
.download-area .download-area-item-icon {
  padding: 0.125rem;
  font-size: 1.25rem;
  background-color: #ff2a2a;
  background-color: #ff2a2a;
}
.download-area .download-area-item-text-left, .download-area .download-area-item-text-right {
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.download-area .download-area-subitem {
  border-bottom: 0.063rem solid #000000 50;
}/*# sourceMappingURL=DownloadAreaNav.css.map */
.download-area-list-item {
  padding: 0.625rem 1.25rem;
  -webkit-user-select: none;
          user-select: none;
}
.download-area-list-item-active {
  background-color: #307cbe !important;
  background-color: rgba(48, 124, 190, 0.4) !important;
}
.download-area-list-item-inactive {
  background-color: initial !important;
}
.download-area-list-item .download-area-list-item-name {
  text-transform: uppercase;
}
.download-area-list-item:nth-child(even) {
  background-color: #307cbe;
  background-color: rgba(48, 124, 190, 0.1);
}
.download-area-list-item .download-area-list-item-icon {
  font-size: 1.563rem;
  cursor: pointer;
  color: #ff2a2a;
  color: #ff2a2a;
}
.download-area-list-item .download-area-list-item-icon-minus {
  border: 0.125rem solid #ff2a2a;
  border-radius: 50%;
}/*# sourceMappingURL=DownloadAreaListItem.css.map */
.download-area-list {
  padding: 0.625rem 1.25rem;
  -webkit-user-select: none;
          user-select: none;
}
.download-area-list-active {
  background-color: #307cbe !important;
  background-color: rgba(48, 124, 190, 0.4) !important;
}
.download-area-list-inactive {
  background-color: initial !important;
}
.download-area-list .download-area-list-name {
  text-transform: uppercase;
}
.download-area-list:nth-child(even) {
  background-color: #307cbe;
  background-color: rgba(48, 124, 190, 0.1);
}
.download-area-list .download-area-list-icon {
  font-size: 1.375rem;
  cursor: pointer;
  color: #ff2a2a;
  color: #ff2a2a;
}
.download-area-list .download-area-list-icon-minus {
  border: 0.125rem solid #ff2a2a;
  border-radius: 50%;
}/*# sourceMappingURL=DownloadAreaList.css.map */
.download-area .download-area-wrapper-list-title {
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: 500;
  color: #535353;
  color: #535353;
}
.download-area .download-area-wrapper-list-button {
  text-transform: inherit;
  padding: 0.313rem 0.938rem !important;
  background-color: #426fad !important;
  background-color: #426fad !important;
}
.download-area .download-area-wrapper-list-button:hover, .download-area .download-area-wrapper-list-button:active, .download-area .download-area-wrapper-list-button:disabled {
  background-color: #31507a !important;
  background-color: #31507a !important;
}
.download-area .download-area-wrapper-list-button:focus {
  box-shadow: none !important;
}
.download-area .download-area-document-header {
  padding: 0.625rem 1.25rem;
  color: #ffffff;
  color: white;
  background-color: #307cbe;
  background-color: #307cbe;
}
.download-area .download-area-document-header span {
  font-weight: 500;
}
.download-area .download-area-document-header-text, .download-area .download-area-document-header-icon {
  font-size: 1.25rem;
}
.download-area .download-area-document-icon-badge-container {
  display: inline-block;
  position: relative;
  cursor: pointer;
}
.download-area .download-area-document-icon-badge-container:first-child {
  margin-left: 0;
}
.download-area .download-area-document-icon-badge-container-icon {
  position: relative;
}
.download-area .download-area-document-icon-badge-container-icon-badge {
  font-size: 0.75rem;
  text-align: center;
  padding-left: 0.313rem;
  padding-right: 0.313rem;
  position: absolute;
  bottom: -0.313rem;
  left: 0.938rem;
  background-color: #ffffff;
  background-color: white;
  color: #535353;
  color: #535353;
}
.download-area .download-area-document-item-col {
  margin-bottom: 3rem;
}/*# sourceMappingURL=DownloadArea.css.map */
.location-week-day .location-week-day-header {
  font-weight: 400;
}
.location-week-day .location-week-day-arrow {
  font-size: 1.25rem;
}/*# sourceMappingURL=LocationWeekDay.css.map */
.location-icon {
  font-size: 1.125rem;
}
.location-region {
  font-weight: 700;
}
.location-text {
  font-size: 0.875rem;
}
.location-gray {
  color: #787878;
  color: #787878;
}
.location-technical-assistance-title {
  font-weight: 500;
  font-size: 0.875rem;
}
.location-phone-number, .location-technical-assistance {
  color: #3a00ff;
  color: #3a00ff;
}/*# sourceMappingURL=Location.css.map */
.location-collection .location-collection-title {
  font-weight: 500;
  font-size: 1.3rem;
}
.location-collection .location-collection-title-icon {
  font-size: 24px;
  vertical-align: top;
}/*# sourceMappingURL=LocationCollection.css.map */
.contact-form-wrapper-submit {
  width: 100% !important;
  text-align: left;
}/*# sourceMappingURL=ContactForm.css.map */
.contact .contact-contact-form {
  margin-top: 100px;
}
@media (min-width: 768px) {
  .contact .contact-contact-form {
    margin-top: 0;
  }
}
.contact .contact-map-title {
  font-size: 1.125rem;
  color: #787878;
  color: #787878;
}
.contact .contact-map {
  width: 100%;
  height: 25rem;
}/*# sourceMappingURL=Contact.css.map */
.privacy-policy .privacy-policy-wrapper {
  margin-top: 1.5rem;
}
.privacy-policy .privacy-policy-wrapper:first-child {
  margin-top: inherit;
}/*# sourceMappingURL=PrivacyPolicy.css.map */
.EventItem_custom_player_wrapper__3X1jN .EventItem_video-react-controls__17zT2 {
  display: none;
}

.EventItem_card_body01__3_nqb {
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0;
  border-top: solid black 1px;
  margin-top: 18px;
}

.EventItem_card_title01__Jh8ow {
  text-align: left;
  align-items: start;
  font-size: 19px;
  font-weight: bold;
  margin-top: 8px;
  margin-left: 0 !important;
  padding-left: 0 !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-family: 'Roboto';
  font-weight: 400;
}

.EventItem_card_text01__SZxc2 {
  text-align: left;
  align-items: start;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
  margin-left: 0 !important;
  padding-left: 0 !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  flex: 1 1;
}



.Event_container__3MWeK {
  margin-bottom: 105px;
  margin-top: 85px;
}


@charset "UTF-8";
.event-maintitle {
  display: block;
}
@media (min-width: 768px) {
  .event-maintitle {
    display: block;
  }
}

.peventer02{
  text-align: center;
}

article {
  align-items: center;
  text-align: center;
}

.descrição {
  font-size: 1.6rem;
}

.image-media {
  margin-top: 50px;
  width: 45vw;
}

@media (max-width: 767px) {
  .image-media {
    width: 100%;
    max-width: 86vw;
  }
}
.event .event-media {
  justify-content: center;
  display: none;
}
@media (min-width: 768px) {
  .event .event-media {
    display: flex;
  }
}
.event .event-title {
  font-size: 2rem;
  text-align: center;
  font-weight: 500;
}
.event .event-divider {
  display: flex;
}
.event .event-divider hr {
  border-top: 0.125rem solid #000000;
  margin: 0;
  width: 8.25rem;
}
@media (min-width: 768px) {
  .event .event-divider {
    display: none;
  }
}
.event .event-date {
  font-size: 0.9rem;
}
@media (min-width: 768px) {
  .event .event-date {
    font-size: 0.75rem;
  }
}
.event .event-type {
  font-weight: 700;
  color: #ff2a2a;
  color: #ff2a2a;
  font-size: 1.1rem;
}
@media (min-width: 768px) {
  .event .event-type {
    font-size: 0.85rem;
  }
}
.event .event-company {
  font-weight: 500;
  color: #535353;
  color: #535353;
  font-size: 1.08rem;
}
@media (min-width: 768px) {
  .event .event-company {
    font-size: 0.83rem;
  }
}
.event .event-location {
  font-size: 1rem;
}
@media (min-width: 768px) {
  .event .event-location {
    font-size: 0.8rem;
  }
}
.event .event-title,
.event .event-type,
.event .event-location {
  text-transform: uppercase;
}
.event .event-disclaimer {
  border-top: 0.063rem;
  border-top-style: solid;
  border-top-color: #00000050;
  margin-top: 4.375rem;
  border-radius: 0 !important;
  padding-top: 2.5rem;
}/*# sourceMappingURL=Events.css.map */
.event {
  min-height: 700px;
  margin: 6.5rem 0 0 0;
  padding: 0 0 0 0; 
}

.event .row {
  margin-left: 0vw !important;
}

.event, .event-wrapper {
  min-height: 700px;
  margin-left: 0vw !important;
  margin-right: 0vw !important;
  justify-content: left;
  align-items: left;
  text-align: left;
}

.teste01{
  margin-top: 2rem;
}

.teste01 .row{
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}


@media screen and (max-width: 1300px) {
  .grid-container02 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.card02 { 
  width: 21.1rem;
  height: 22rem;
  max-width: none;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  margin: 0 91px;
  padding: 0;  
}



.card-image02 {
  width: 100%;
  height: 18rem;
  object-fit: contain;
}

.card-body02 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 0px 16px 0px;
  border-top: solid black 1px;
  margin-top: 22px;
}

.card-title02 {
  text-align: left;
  align-items: start;
  font-size: 19px;
  font-weight: bold;
  margin-top: 8px;
  margin-left: 0 !important;
  padding-left: 0 !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-family: 'Roboto';
  font-weight: 400;
}


/* --------------------------------- REACT CAROUSEL ORGININAL ----------------------------------------------------------- */

@media (min-width: 992px){
  .container-lg, .container-md, .container-sm, .container {
    max-width: 1041px;
    }
  }
  
  @media (min-width: 1200px){
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1100px;
   }
  }
  .image-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .image-carousel .image-media {
    margin: 0 auto;
      margin-bottom: 0px;
    max-height: 500px;
    width: auto;
    max-width: 100%;
  }
  
  .container-media{
    justify-content: center;
    text-align: center;
    align-items:center ;
  }
  
  /* --------------------------------- REACT CAROUSEL ORGININAL ----------------------------------------------------------- */
  
  .pagination{
      overflow-y: scroll;
  }
.event , .event-wrapper {
  min-height: 700px;  
  margin-left: 10vw;
  margin-right: 5vw;
  justify-content: center;
  align-items: center;
  text-align: center;  
}

.event-wrapper .row{
  margin-top: 0vw;
  margin-left: 0vw;
  flex-wrap: wrap;
  /* justify-content: center; */
  align-items: center;
  text-align: center;
  width: 70vw;
}

.teste001{
  margin-top: 0vw;
}







@media screen and (max-width: 1300px) {
    .grid-container01 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  .card01 { 
    width: 18.1rem;
    height: 23rem;
    max-width: none;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  }
  
  .card-image01 {
    width: 100%;
    height: 12rem;
    object-fit: cover;
  }
  
  .card-body01 {
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0;
    border-top: solid black 1px;
    margin-top: 18px;
  }
  
  .card-title01 {
    text-align: left;
    align-items: start;
    font-size: 19px;
    font-weight: bold;
    margin-top: 8px;
    margin-left: 0 !important;
    padding-left: 0 !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    font-family: 'Roboto';
    font-weight: 400;
  }
  
  .card-text01 {
    text-align: left;
    align-items: start;
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
    margin-left: 0 !important;
    padding-left: 0 !important;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    flex: 1 1;
  }
  
  .grid-container01 {
    width: 100%;
    display: grid;
    /* grid-template-columns: repeat(3, 1fr) !important;
    grid-template-columns: repeat(auto-fit, minmax(18.1rem, 1fr)); */
    grid-gap: 50px;
    gap: 50px;
    justify-content: center;
  }
  
.event , .event-wrapper {
  min-height: 450px;  
  margin-left: 10vw;
  margin-right: 5vw;
  justify-content: left;
  align-items: left;
  text-align: left;  
}

.event-wrapper .row{
  margin-top: 0vw;
  margin-left: 0vw;
  flex-wrap: wrap;
  justify-content: left;
  align-items: left;
  text-align: left;
  /* width: 70vw; */
}

.event .row{
  margin-left: 7vw;
}

.teste001{
  margin-top: 0vw;
}

.dados{
  text-align: center;
}





@media screen and (max-width: 1300px) {
    .grid-container01 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  .card01 { 
    width: 18.1rem;
    height: 23rem;
    max-width: none;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  }
  
  .card-image01 {
    width: 100%;
    height: 12rem;
    object-fit: cover;
  }
  
  .card-body01 {
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0;
    border-top: solid black 1px;
    margin-top: 18px;
  }
  
  .card-title01 {
    text-align: left;
    align-items: start;
    font-size: 19px;
    font-weight: bold;
    margin-top: 8px;
    margin-left: 0 !important;
    padding-left: 0 !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    font-family: 'Roboto';
    font-weight: 400;
  }
  
  .card-text01 {
    text-align: left;
    align-items: start;
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
    margin-left: 0 !important;
    padding-left: 0 !important;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    flex: 1 1;
  }
  
  .grid-container01 {
    width: 100%;
    display: grid;
    /* grid-template-columns: repeat(3, 1fr) !important;
    grid-template-columns: repeat(auto-fit, minmax(18.1rem, 1fr)); */
    grid-gap: 50px;
    gap: 50px;
    justify-content: center;
  }
  
/* --------------------------------- REACT CAROUSEL ORGININAL ----------------------------------------------------------- */

@media (min-width: 992px){
.container-lg, .container-md, .container-sm, .container {
  max-width: 1041px;
  }
}

@media (min-width: 1200px){
.container-xl, .container-lg, .container-md, .container-sm, .container {
  max-width: 1100px;
 }
}
.image-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.image-carousel .image-media {
  margin: 0 auto;
    margin-bottom: 0px;
  max-height: 500px;
  width: auto;
  max-width: 100%;
}

.container-media{
  justify-content: center;
  text-align: center;
  align-items:center ;
}

/* --------------------------------- REACT CAROUSEL ORGININAL ----------------------------------------------------------- */

.pagination{
    overflow-y: scroll;
}
/* body {
    box-sizing: border-box;

} */
.testeh5{
    color: #8a8a8a;
}

/* .container{
    width: 100vw;
    height: 1000px;
} */
.DataSheetType1_body__h1Dc_ {
    background-color: rgb(66, 64, 64);
    padding: 0px;
    margin-top: 100px;padding-top: 20px;
    width: 100% !important;
}
/* 
.watermark{      
    font-family: 'Kanit', sans-serif;
    font-weight: 700;
    color: rgb(177, 154, 124);
    opacity: 0.3;    
}

.ma  {
    font-size: 6.5em;
    top: 0.61em;
    left: 0px;
}
.fi {
    font-size: 6.5em;
    top: 1.34em;
    left: 4px;
}

.rol{
    font-size: 6.43em;
    top: 2.08em;
    left: 2px;
}

.ma, .fi, .rol {  
    position: absolute;
} */

.DataSheetType1_backgroundImageColor__1KnQK {
    background-color: #ffffff;
}

.DataSheetType1_h1__1GcX5 {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 300;
    color: rgb(16, 61, 79); 
    font-size: 3.2rem;
    text-transform: uppercase;
}

.DataSheetType1_h3__1IYX6 {
    color: rgb(16, 61, 79);
    font-size: 18px; 
    text-transform: uppercase;
}
.DataSheetType1_h5__3jgIs {
    color: rgb(16, 61, 79);
    font-size: 1.6rem; 
    text-transform: uppercase;
}

.DataSheetType1_h6__3ArSq{
    font-size: 1.3rem;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 6px;
    justify-content: end;
}

.DataSheetType1_h5__3jgIs, .DataSheetType1_h6__3ArSq {
    text-align: right;
}

.DataSheetType1_greentext__1hAvR{
    /* color:#0b7c0b; */
    color: rgb(16, 61, 79); 
}

.DataSheetType1_parent__3rpn0 {
    display: flex;
}

p{
    margin-bottom: 0;
}

.DataSheetType1_sibilingOne__2xw3J {
    width: 40%;
}

.DataSheetType1_sibilingTwo__UF2rh {
    width: 55%;
    padding-left: 10px;
}

.DataSheetType1_container__JODmL {
    margin: 1em 2em 2em 2em;
    background-color: white;
    width: 1200px;
    height: 60cm;
    padding: 30px;
    /* height: 1500px; */
}

.DataSheetType1_container1__1cLa2 {
    margin: 1em 2em 2em 2em;
    background-color: white;
    width: 1200px;
    height:45cm;
    padding: 30px;
    /* height: 22cm; */
}

.DataSheetType1_header__T-iBQ {
    justify-content: end;
}

.DataSheetType1_paragraph__sVtUB {
    font-family: 'ROBOTO', 'Josefin Sans', sans-serif;
    /* font-weight: 300; */
    text-align: left;
    /* font-size: 18px;  */
    font-size: 16px; 
    line-height: 16px;  
     margin-bottom: 35px;
    padding-left: 15px;
}

/* .separatorParagraph {

} */


.DataSheetType1_cardTitle__EFHSp {
    /* position: relative; */
    text-align: left;
    font-size: 1.45em;
    /* color:#0b7c0b; */
    padding-left: 40px;
    color: rgb(16, 61, 79); 
    text-transform: uppercase;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    margin-top: 2rem;
}

.DataSheetType1_cardPosition01__3QyNe {
    margin-top: -40px;
}


.DataSheetType1_cardTitleTechnicalInformation__1_8ZB{
        text-align: center;
        font-size: 1.5em;
        /* color: #0b7c0b; */
        color: rgb(16, 61, 79); 
        text-transform: uppercase;
        font-family: 'Josefin Sans', sans-serif;
        font-weight: bolder;
        margin-top: 2rem;    
}

.DataSheetType1_cardImage__2pDEI {
    margin-top: 30px;
    width: 100%;
    height: 500px;
    position: relative;
    margin-left: -90px;
    margin-bottom: 20px;
}
.DataSheetType1_imageMain__1iyXC {
    width: 130%;
    /* object-fit: cover; */
    /* fit: 300 300; */
    max-width: 160%;
    /* height: 100%; */
    padding: 30px 10px 0 90px;
}


.DataSheetType1_imageColorHeader__B5ysD {
    width: 100px;
}

.DataSheetType1_imageColor__2HkEK {
    width: 67px;
}
.DataSheetType1_hr__29t9S {
    margin-top: 1px;
    border: 0;
    /* border-top-width: 0px;
    border-top-style: none;
    border-top-color: currentcolor; */  
    border-top: 1px solid rgba(0, 0, 0, 0.54);
}

.DataSheetType1_separatorOne__TvNUR {
    margin:0;
    padding: 0;
}

.DataSheetType1_containerColor__3HZvv{
    width: 100%;
}

.DataSheetType1_cardColor__96amy{
    width: 22%;
}

.DataSheetType1_cardContainer__1JKCM{
    width: 68%;
    margin-left: 100px;
    padding-left: 15px;
    justify-content: right;
    align-items: end;
    text-align: end;
}

.DataSheetType1_textFooterColor__1sTn8 {
    margin-top: 15px;
    color: gray;
    font-size: 12px;
    text-align: left;
    margin-left: 15px;
}

.DataSheetType1_textFooter1__gN66I {
    text-align: center;
}

.DataSheetType1_footerFixed1__EbJbk {
    position: absolute;
    margin-bottom: 0px; 
    width: 1050px;
    top: 1685px;
    left: 100px;
    text-align: center;
    padding: 10px 10px 0px 10px;
    font-size: 12px;
    color: gray;
  }

  .DataSheetType1_footerFixed2__zzOLd {
    position: absolute;   
    padding-bottom: 0;
    margin-bottom: 0px; 
    top: 4135px;
    left: 120px;
    width: 1050px;
    text-align: center;
    padding: 10px 10px 0px 10px;
    font-size: 12px;
    color: gray;
  }
  .DataSheetType1_cardImageSecondPage__1avAj {
    margin-top: -10px;
    width: 120%;
    justify-content: center;
  }

  .DataSheetType1_imageSecondPage__3abiz{
    justify-content: center;
    width: 60%;
    margin-left: 147px;
  }
  
  .DataSheetType1_paragraphSecondPage__2dPqn {
    font-family: 'ROBOTO', sans-serif;
    /* font-weight: 300; */
    text-align: left;
    font-size: 18px; 
    line-height: 4px;  
     margin-bottom: 35px;
    padding-left: 15px;
  }

  .DataSheetType1_gray_row__C6am9 {
    background-color: #f2f2f2; /* Cinza */
  }
  
  .DataSheetType1_white_row__1Aa_5 {
    background-color: #ffffff; /* Branco */
  }
  .DataSheetType1_card__1mhV3 {
    margin-top: -10px;
  }
  .DataSheetType1_content_table__3aTdD{
    margin-top: -10px;
    width: 100%;
    justify-content: center;
    /* background-color: yellow; */
  }

  .DataSheetType1_table__18rPP {
    margin-left: 250px;
    justify-content: center;
    align-items: center;
    text-align: left;
    font-size: 13.8px;
    font-weight: 300;
    /* width: 715px; */
}

table {
    width: 715px;
  height: 489px;
}

li{
    line-height: 32px;
}

p{
    line-height: 16px;
}

.DataSheetType1_loadingOverlay__23QT1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.99);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5000; 
  } 
   
  .DataSheetType1_overlay__1jcd6 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo preto semi-transparente */
    z-index: 9999; /* Certifique-se de que está acima de todos os outros elementos */
  }
  
  .DataSheetType1_tableHeader__1eGHH {
    background-color: gray;
    color: white;
  }

  .DataSheetType1_mafirolCom__39vgJ{
    padding-top: 19px;
    padding-right: 35px;
  }
.DataSheetType2_body__1icTO {
    background-color: rgb(66, 64, 64);
    padding: 0px;
    margin-top: 100px;padding-top: 20px;
    width: 100% !important;
}


.DataSheetType2_watermark__3FGzU{      
    font-family: 'Kanit', sans-serif;
    font-weight: 700;
    /* color: rgb(182, 124, 49); */
    color: rgba(177, 154, 124, 0.514);
    opacity: 0.3;    
}

.DataSheetType2_ma__1hl9G  {
    font-size: 6em;
    top: 0.73em;
    left: 0.052em;
}

.DataSheetType2_fi__2Yewm {
    font-size: 6.10em;
    top: 1.47em;
    left: 0.052em;
}

.DataSheetType2_rol__3gJ7-{
    font-size: 6.28em;
    top: 2.11em;
    left: 0.045em;
}

.DataSheetType2_ma__1hl9G, .DataSheetType2_fi__2Yewm, .DataSheetType2_rol__3gJ7- {  
    position: absolute;
}


.DataSheetType2_h1__yEfcl {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 300;
    color: rgb(16, 61, 79); 
    font-size: 3.2rem;
    text-transform: uppercase;
}

.DataSheetType2_h3__2hBZL {
    color: rgb(16, 61, 79);
    font-size: 18px; 
    text-transform: uppercase;
}
.DataSheetType2_h5__S0oYv {
    color: rgb(16, 61, 79);
    font-size: 1.6rem; 
    text-transform: uppercase;
}

.DataSheetType2_h6__1RYkf{
    font-size: 1.3rem;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 6px;
    justify-content: end;
}

.DataSheetType2_h5__S0oYv, .DataSheetType2_h6__1RYkf {
    text-align: right;
}

.DataSheetType2_greentext__1hN2o{
    /* color:#0b7c0b; */
    color: rgb(16, 61, 79); 
}

.DataSheetType2_parent__3-8TH {
    display: flex;
}

p{
    margin-bottom: 0;
}

.DataSheetType2_sibilingOne__2cLyJ {
    width: 40%;
}

.DataSheetType2_sibilingTwo__q_SQq {
    width: 55%;
    padding-left: 10px;
}

.DataSheetType2_container__2qz33 {
    margin: 1em 2em 2em 2em;
    background-color: white;
    width: 1200px;
    height: 60cm;
    padding: 30px;
    /* height: 1500px; */
}

.DataSheetType2_container1__S21ML {
    margin: 1em 2em 2em 2em;
    background-color: white;
    width: 1200px;
    height:45cm;
    padding: 30px;
    /* height: 22cm; */
}

.DataSheetType2_header__DYNJV {
    justify-content: end;
}

.DataSheetType2_paragraph__1eKHq {
    font-family: 'ROBOTO', 'Josefin Sans', sans-serif;
    /* font-weight: 300; */
    text-align: left;
    /* font-size: 18px;  */
    font-size: 16px; 
    line-height: 16px;  
     margin-bottom: 35px;
    padding-left: 15px;
}

/* .separatorParagraph {

} */


.DataSheetType2_cardTitle__1sXZa {
    text-align: left;
    font-size: 1.45em;
    /* color:#0b7c0b; */
    padding-left: 40px;
    color: rgb(16, 61, 79); 
    text-transform: uppercase;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    margin-top: 10rem;
}

.DataSheetType2_cardPosition01__13VaM {
    margin-top: -40px;
}


.DataSheetType2_cardTitleTechnicalInformation__1iSMK{
        text-align: center;
        font-size: 1.5em;
        /* color: #0b7c0b; */
        color: rgb(16, 61, 79); 
        text-transform: uppercase;
        font-family: 'Josefin Sans', sans-serif;
        font-weight: bolder;
        margin-top: 2rem;    
}

.DataSheetType2_cardImage__1h3p2 {
    margin-top: 30px;
    width: 100%;
    height: 500px;
    position: relative;
    margin-left: 30px;
    margin-bottom: 90px;
}
.DataSheetType2_imageMain__2Q-LZ {
    margin-top: 10px;
    /* width: 160%; */
    /* object-fit: cover; */
    /* fit: 300 300; */
    max-width: 600px;
    padding: 0px 0px 0 50px;
}


.DataSheetType2_imageColorHeader__36NAM {
    width: 100px;
}

.DataSheetType2_imageColor__16S0C {
    width: 67px;
}
.DataSheetType2_hr__3TcAl {
    margin-top: 1px;
    border: 0;
    /* border-top-width: 0px;
    border-top-style: none;
    border-top-color: currentcolor; */  
    border-top: 1px solid rgba(0, 0, 0, 0.54);
}

.DataSheetType2_separatorOne__2PDPh {
    margin:0;
    padding: 0;
}

.DataSheetType2_containerColor__1DBEl{
    width: 100%;
}

.DataSheetType2_cardColor__3Qftp{
    width: 22%;
}

.DataSheetType2_cardContainer__sdn0d{
    width: 68%;
    margin-left: 100px;
    padding-left: 15px;
    justify-content: right;
    align-items: end;
    text-align: end;
}

.DataSheetType2_textFooterColor__113S0 {
    margin-top: -55px;
    color: rgb(16, 61, 79); 
    font-size: 14px;
    text-align: left;
    margin-left: 22em;
}

.DataSheetType2_textFooter1__3b1tS {
    text-align: center;
}

.DataSheetType2_footerFixed1__3VMx_ {
    position: absolute;
    margin-bottom: 0px; 
    width: 1050px;
    top: 1685px;
    left: 100px;
    text-align: center;
    padding: 10px 10px 0px 10px;
    font-size: 12px;
    color: gray;
  }

  .DataSheetType2_footerFixed2__2pUkZ {
    position: absolute;   
    padding-bottom: 0;
    margin-bottom: 0px; 
    top: 4135px;
    left: 120px;
    width: 1050px;
    text-align: center;
    padding: 10px 10px 0px 10px;
    font-size: 12px;
    color: gray;
  }
  .DataSheetType2_cardImageSecondPage__1qn9j {
    margin-top: 10px;
    width: 120%;
    justify-content: center;
  }

  .DataSheetType2_imageSecondPage__1wc9o{
    justify-content: center;
    max-width: 135%;
    margin-left: -90px;
  }
  

  .DataSheetType2_gray_row__2PEY4 {
    background-color: #f2f2f2; /* Cinza */
  }
  
  .DataSheetType2_white_row__1Qkz7 {
    background-color: #ffffff; /* Branco */
  }
  
  



p{
    line-height: 16px;
}

.DataSheetType2_loadingOverlay__2emIn {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.99);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5000; 
  } 
   
  .DataSheetType2_overlay__3g8kd {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo preto semi-transparente */
    z-index: 9999; /* Certifique-se de que está acima de todos os outros elementos */
  }
  
  .DataSheetType2_tableHeader__3qqqo {
    background-color: gray;
    color: white;
  }

  .DataSheetType2_mafirolCom__2Gz7g{
    padding-top: 19px;
    padding-right: 35px;
  }
.PDFGenerator_body__1h9l2 {
    background-color: rgb(66, 64, 64);
    padding: 0px;
    margin-top: 100px;padding-top: 20px;
    width: 100% !important;
}

.PDFGenerator_h1__2b75I {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 300;
    color: rgb(16, 61, 79); 
    font-size: 3.2rem;
    text-transform: uppercase;
}

.PDFGenerator_h3__2W0AZ {
    color: rgb(16, 61, 79);
    font-size: 18px; 
    text-transform: uppercase;
}
.PDFGenerator_h5__2J8uC {
    color: rgb(16, 61, 79);
    font-size: 1.6rem; 
    text-transform: uppercase;
}

.PDFGenerator_h6__1SfSf{
    font-size: 1.3rem;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 6px;
    justify-content: end;
}

.PDFGenerator_h5__2J8uC, .PDFGenerator_h6__1SfSf {
    text-align: right;
}

.PDFGenerator_greentext__Pq2Kc{
    /* color:#0b7c0b; */
    color: rgb(16, 61, 79); 
}

.PDFGenerator_parent__2oa4E {
    display: flex;
}

p{
    margin-bottom: 0;
}

.PDFGenerator_sibilingOne__3YxL5 {
    width: 40%;
}

.PDFGenerator_sibilingTwo__2gry5 {
    width: 55%;
    padding-left: 10px;
}

.PDFGenerator_container__1OqXn {
    margin: 1em 2em 2em 2em;
    background-color: white;
    width: 1200px;
    height: 60cm;
    padding: 30px;
    /* height: 1500px; */
}

.PDFGenerator_container1__GQ56Z {
    margin: 1em 2em 2em 2em;
    background-color: white;
    width: 1200px;
    height:45cm;
    padding: 30px;
    /* height: 22cm; */
}

.PDFGenerator_header__3a2Ob {
    justify-content: end;
}

.PDFGenerator_paragraph__10VId {
    font-family: 'ROBOTO', 'Josefin Sans', sans-serif;
    /* font-weight: 300; */
    text-align: left;
    /* font-size: 18px;  */
    font-size: 16px; 
    line-height: 16px;  
     margin-bottom: 35px;
    padding-left: 15px;
}

/* .separatorParagraph {

} */


.PDFGenerator_cardTitle__2PUhi {
    text-align: left;
    font-size: 1.45em;
    /* color:#0b7c0b; */
    padding-left: 40px;
    color: rgb(16, 61, 79); 
    text-transform: uppercase;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    margin-top: 2rem;
}

.PDFGenerator_cardPosition01__2722- {
    margin-top: -40px;
}


.PDFGenerator_cardTitleTechnicalInformation__2I0Sr{
        text-align: center;
        font-size: 1.5em;
        /* color: #0b7c0b; */
        color: rgb(16, 61, 79); 
        text-transform: uppercase;
        font-family: 'Josefin Sans', sans-serif;
        font-weight: bolder;
        margin-top: 2rem;    
}

.PDFGenerator_cardImage__2QNoZ {
    margin-top: 30px;
    width: 100%;
    height: 500px;
    position: relative;
    margin-left: -90px;
    margin-bottom: 90px;
}
.PDFGenerator_imageMain__1ugiv {
    width: 130%;
    /* object-fit: cover; */
    /* fit: 300 300; */
    max-width: 160%;
    /* height: 100%; */
    padding: 30px 10px 0 90px;
}


.PDFGenerator_imageColorHeader__3YhmV {
    width: 100px;
}

.PDFGenerator_imageColor___KYHp {
    width: 67px;
}
.PDFGenerator_hr__xJrFh {
    margin-top: 1px;
    border: 0;
    /* border-top-width: 0px;
    border-top-style: none;
    border-top-color: currentcolor; */  
    border-top: 1px solid rgba(0, 0, 0, 0.54);
}

.PDFGenerator_separatorOne__30Xgm {
    margin:0;
    padding: 0;
}

.PDFGenerator_containerColor__3RFq8{
    width: 100%;
}

.PDFGenerator_cardColor__cbJfh{
    width: 22%;
}

.PDFGenerator_cardContainer__2RHNk{
    width: 68%;
    margin-left: 100px;
    padding-left: 15px;
    justify-content: right;
    align-items: end;
    text-align: end;
}

.PDFGenerator_textFooterColor__1DFGU {
    margin-top: 15px;
    color: gray;
    font-size: 12px;
    text-align: left;
    margin-left: 15px;
}

.PDFGenerator_textFooter1__1W7KH {
    text-align: center;
}

.PDFGenerator_footerFixed1__1yzyo {
    position: absolute;
    margin-bottom: 0px; 
    width: 1050px;
    top: 1685px;
    left: 100px;
    text-align: center;
    padding: 10px 10px 0px 10px;
    font-size: 12px;
    color: gray;
  }

  .PDFGenerator_footerFixed2__l9F2w {
    position: absolute;   
    padding-bottom: 0;
    margin-bottom: 0px; 
    top: 4135px;
    left: 120px;
    width: 1050px;
    text-align: center;
    padding: 10px 10px 0px 10px;
    font-size: 12px;
    color: gray;
  }
  .PDFGenerator_cardImageSecondPage__1gT1a {
    margin-top: -10px;
    width: 120%;
    justify-content: center;
  }

  .PDFGenerator_imageSecondPage__2hcJ-{
    justify-content: center;
    width: 60%;
    margin-left: 147px;
  }
  
  .PDFGenerator_paragraphSecondPage__Urg64 {
    font-family: 'ROBOTO', sans-serif;
    /* font-weight: 300; */
    text-align: left;
    font-size: 18px; 
    line-height: 4px;  
     margin-bottom: 35px;
    padding-left: 15px;
  }

  .PDFGenerator_gray_row__1p45d {
    background-color: #f2f2f2; /* Cinza */
  }
  
  .PDFGenerator_white_row__3dTGu {
    background-color: #ffffff; /* Branco */
  }
  
  .PDFGenerator_content_table__3geTR{
    width: 100%;
    justify-content: center;
    /* background-color: yellow; */
  }

.PDFGenerator_table__cuY4_ {
    margin-left: 250px;
    justify-content: center;
    align-items: center;
    text-align: left;
}

table {
    width: 715px;
  height: 489px;
}

li{
    line-height: 32px;
}

p{
    line-height: 16px;
}

.PDFGenerator_loadingOverlay__1qDO2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.99);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5000; 
  } 
   
  .PDFGenerator_overlay__1GRLf {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo preto semi-transparente */
    z-index: 9999; /* Certifique-se de que está acima de todos os outros elementos */
  }
  
  .PDFGenerator_tableHeader__1ITeD {
    background-color: gray;
    color: white;
  }

  .PDFGenerator_mafirolCom__14FK-{
    padding-top: 19px;
    padding-right: 35px;
  }
.Opportunity_box__1ttMF {
    background-color: #ffffff;
    padding: 20px;
    margin-bottom: 20px;
}

.Opportunity_productName__3kbwW {
    margin: 15px 0;
}

.Opportunity_price__2tXuK {
    font-weight: bold;
    margin-bottom: 15px;
}

.Opportunity_card__361oc {
    height: 100%;
    display: flex;
    justify-content: center;

}

.Opportunity_cardMedia__25DrP {
    padding-top: 56%;
    width: 92%;
}
.content {
    text-align: center;
    margin-bottom: 30px;
  }
  .header {
    font-size: 30px;
  }
  
  .img-body img {
    display: flex;
    margin: 0 auto;
    height: 40vh;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  
  .img-body {
    width: 200px;
    aspect-ratio: 1/1;
    outline-color: red;
    display: inline-block;
  }
  
  .img-cut img {
    width: 100%;
  }

  .shadow {
    color: #164176;
    font-size: 3.1rem !important;
    text-align: center;
    font-weight: 400;
    position: relative;    
    letter-spacing: -2px !important;
}
   
/* 
  .shadow::after {
    content: attr(data-content);
    position: relative;
    top: -45px;
    left: 0;
    z-index: -1;
    color: rgba(40, 95, 145, 0.08);
    font-size: 4.5rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 400;
    display: block;
    transform: translateY(-50%);
  } */

  .contentProduct {
    display: flex;
  }

  .product-info , .product-image {
    /* margin-top: 35px; */
    width: 50%;
}
  @media only screen and (max-width: 915px) {
    .contentProduct {
        display: block;
        justify-content: center;
    }

    .product-info , .product-image {
        margin-top: 35px;
        width: 100%;
    }

    .title, .subtitle {
        font-size: 30px !important;
    }
  }


  /* ////------------------------------------------------ */
  

  .cardColor{
    width: 22%;
}

.cardContainer{
    width: 68%;
    margin-left: 100px;
    padding-top: 30px;
    padding-left: 15px;
    justify-content: right;
    align-items: end;
    text-align: end;
}


.imageColor {
  width: 46px !important;
}

.thumbnailContainer {
  padding-top: 95px;
}

.thumbnailImage {
   width: 130px;
   height: auto ; 
   margin: 5px;
   cursor: pointer; 
   border: solid 1px #d7d0d0; 
    border-radius: 10%;
}
.PDFGenerator_imageSecondPage__2QZC4{
    justify-content: center;
    width: 60%;
    margin-left: 147px;
  }
  

  .PDFGenerator_gray_row__1TpCc {
    background-color: #f2f2f2;
  }
  
  .PDFGenerator_white_row__3cPfO {
    background-color: #ffffff;
  }
  
  .PDFGenerator_content_table__2odAA{
    width: 80%;
    justify-content: center;
    
  }

.PDFGenerator_table__1dTpH {    
    justify-content: center;
    align-items: center;
    text-align: left;
}

table {
    width: 100%;
    height: 489px;
    margin-top: 20px;
}

li{
    line-height: 32px;
}

p{
    line-height: 16px;
}

   

  .PDFGenerator_tableHeader__316ct {
    background-color: gray;
    color: white;
  }

.variant .variant-wrapper {
  border-bottom: 1px solid #707070 50;
  padding-bottom: 30px;
}
.variant .variant-wrapper-tabs {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #333c4b;
  background-color: #333c4b;
}
.variant .variant-wrapper-tabs-button-prev-icon, .variant .variant-wrapper-tabs-button-next-icon, .variant .variant-wrapper-tabs-button-prev-icon-hide, .variant .variant-wrapper-tabs-button-next-icon-hide {
  font-size: 30px;
}
.variant .variant-wrapper-tabs-button-prev-icon, .variant .variant-wrapper-tabs-button-next-icon {
  cursor: pointer;
  color: #ffffff;
  color: rgba(255, 255, 255, 0.8);
}
.variant .variant-wrapper-tabs-button-prev-icon:hover, .variant .variant-wrapper-tabs-button-next-icon:hover {
  color: #ffffff;
  color: white;
}
.variant .variant-wrapper-tabs-button-prev-icon-hide, .variant .variant-wrapper-tabs-button-next-icon-hide {
  color: #ffffff;
  color: rgba(255, 255, 255, 0.1);
}
.variant .variant-wrapper-tabs-col {
  border-bottom: 2px solid #ffffff 82;
}
.variant .variant-wrapper-tabs-col a {
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
  color: #707070 !important;
  color: #707070 !important;
  background-color: #333c4b !important;
  background-color: #333c4b !important;
}
.variant .variant-wrapper-tabs-col a.active {
  border-bottom: 3px solid #ffffff;
  border-radius: 0;
  color: #ffffff !important;
  color: white !important;
}/*# sourceMappingURL=Variant.css.map */
.language-switcher {
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
}
@media (min-width: 576px) {
  .language-switcher {
    font-size: 16px;
  }
}/*# sourceMappingURL=LanguageSwitcher.css.map */
.navbar .navbar-menu {
  margin-right: auto;
}
.navbar .navbar-location-icon,
.navbar .navbar-user-icon {
  font-size: 25px;
}
.navbar .navbar-search {
  font-size: 21px;
}
@media (min-width: 576px) {
  .navbar .navbar-search {
    font-size: 25px;
  }
}
.navbar .navbar-logo {
  height: 28px;
}
@media (min-width: 576px) {
  .navbar .navbar-logo {
    height: 33px;
  }
}
.navbar .navbar-location,
.navbar .navbar-user {
  display: none;
}
@media (min-width: 768px) {
  .navbar .navbar-location,
  .navbar .navbar-user {
    display: inline;
  }
}

.navigation-bar {
  background-color: #edeef0;
  background-color: #edeef0;
}

.navigation-bar-white {
  background-color: #ffffff;
}/*# sourceMappingURL=NavigationBar.css.map */
.disclaimer {
  font-size: 0.7rem;
}/*# sourceMappingURL=Disclaimer.css.map */
.footer {
  color: #313957;
  color: #313957;
  background-color: #ffffff;
  background-color: #EBEBEB;
}
.footer .footer-wrapper-title {
  text-transform: uppercase;
  text-align: left;
  font-size: 16px;
}
.footer .footer-wrapper hr {
  margin-left: 0;
  margin-top: 0;
  border: none;
  height: 0.15rem;
  background-color: #313957;
  /* background: url("https://cdn-icons-png.flaticon.com/512/10080/10080935.png"); */
  width: 10.688rem;
}
.footer .footer-wrapper-list {
  list-style: none;
  padding-left: 0;
  text-transform: uppercase;
}
.footer .footer-wrapper-item {
  font-size: 13px;
}
.footer .footer-wrapper-disclaimer {
  text-align: center;
  margin-bottom: 0;
}
.footer .footer-wrapper-social-media {
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .footer .footer-wrapper-social-media {
    margin-bottom: 0;
  }
}
.footer .footer-wrapper-privacy-policy {
  font-size: 0.7rem;
}
.footer .footer-wrapper-privacy-policy:hover {
  text-decoration: underline !important;
}/*# sourceMappingURL=Footer.css.map */
.item a {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.item .item-wrapper {
  text-align: left;
}
.item .item-wrapper * {
  text-transform: uppercase;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (min-width: 768px) {
  .item .item-wrapper {
    text-align: right;
  }
}
.item .item-wrapper-hover * {
  color: #000000 !important;
  color: black !important;
}
.item * {
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  color: white;
}
@media (min-width: 768px) {
  .item * {
    font-size: 29px;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    color: #53555a;
    color: #53555a;
  }
}
@media (min-width: 992px) {
  .item * {
    font-size: 32px;
  }
}
.item > div {
  padding: 0.313rem;
  width: auto;
}
.item .item-pointer {
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}
.item .item-pointer * {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.item .item-pointer-hover * {
  color: #000000 !important;
  color: black !important;
}

.item-image-color a:hover {
  color: #ffffff;
  color: white;
}

.item-color a {
  color: #ffffff;
  color: rgba(255, 255, 255, 0.52);
}

.subitem .subitem-wrapper {
  text-align: right;
}
.subitem .subitem-wrapper a {
  font-size: 18px;
  font-weight: 400;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  color: #53555a;
  color: rgba(83, 85, 90, 0.8);
}
.subitem .subitem-wrapper a:hover, .subitem .subitem-wrapper a:active, .subitem .subitem-wrapper a:focus {
  color: #000000 !important;
  color: black !important;
}/*# sourceMappingURL=MenuItem.css.map */
.mobile-menu-subitem .mobile-menu-subitem-title {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 22px;
}
.mobile-menu-subitem .mobile-menu-subitem-icon {
  cursor: pointer;
}
.mobile-menu-subitem .mobile-menu-subitem-icon * {
  font-size: 35px;
}
.mobile-menu-subitem .mobile-menu-subitem-subitems {
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;
}
.mobile-menu-subitem .mobile-menu-subitem-subitems a {
  font-size: 15px;
}/*# sourceMappingURL=MobileMenuSubitem.css.map */
@-webkit-keyframes img-show-up {
  0% {
    right: 0;
  }
  100% {
    right: 100%;
  }
}
@keyframes img-show-up {
  0% {
    right: 0;
  }
  100% {
    right: 100%;
  }
}
@-webkit-keyframes phone-ripple-effect {
  0% {
    box-shadow: 0 0 0 0 #ffffff 50;
  }
  100% {
    box-shadow: 0 0 0 1.25rem rgba(228, 149, 152, 0);
  }
}
@keyframes phone-ripple-effect {
  0% {
    box-shadow: 0 0 0 0 #ffffff 50;
  }
  100% {
    box-shadow: 0 0 0 1.25rem rgba(228, 149, 152, 0);
  }
}
@-webkit-keyframes phone-open {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes phone-open {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes phone-close {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}
@keyframes phone-close {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}
@-webkit-keyframes animate-arrow {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(-1.25rem, -1.25rem);
            transform: rotate(45deg) translate(-1.25rem, -1.25rem);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(1.25rem, 1.25rem);
            transform: rotate(45deg) translate(1.25rem, 1.25rem);
  }
}
@keyframes animate-arrow {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(-1.25rem, -1.25rem);
            transform: rotate(45deg) translate(-1.25rem, -1.25rem);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(1.25rem, 1.25rem);
            transform: rotate(45deg) translate(1.25rem, 1.25rem);
  }
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.menu {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1001;
  background-image: url(/static/media/logo_sirius.9e27a547.svg);
  background-position: center;
  background-repeat: no-repeat;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s linear;
  background-color: #ffffff;
  background-color: white;
  background-size: 200px;
  background-color: #bebebe;
  background-color: #bebebe;
}
.menu .menu-section {
  position: relative;
  text-align: center;
  margin-top: 1.875rem;
  max-width: 800px !important;
}
@media (min-width: 768px) {
  .menu .menu-section {
    max-width: 1300px !important;
  }
}
.menu .menu-section-desktop {
  top: 25%;
}
.menu .menu-section-mobile {
  top: 20%;
}
.menu .menu-section-mobile-subnav {
  top: 12%;
}
.menu .menu-gammas {
  height: 100%;
  width: 55%;
  position: absolute;
  right: 0;
}
.menu .menu-gammas-media {
  height: 100%;
  width: 0;
  position: absolute;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition: width 0.4s linear;
}
.menu .menu-gammas-media-hover {
  width: 100%;
}
.menu .menu-right {
  display: none;
}
@media (min-width: 768px) {
  .menu .menu-right {
    display: block;
  }
}
.menu .menu-footer {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
}
.menu .menu-footer-desktop {
  display: none;
}
@media (min-width: 768px) {
  .menu .menu-footer-desktop {
    display: flex;
  }
}
.menu .menu-footer-mobile {
  display: block;
}
@media (min-width: 768px) {
  .menu .menu-footer-mobile {
    display: none;
  }
}
.menu .menu-footer-mobile-location {
  border-top: 1px solid #ffffff;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.menu .menu-footer-mobile-location:hover, .menu .menu-footer-mobile-location:active, .menu .menu-footer-mobile-location:focus {
  border-top: 1px solid #bebebe !important;
  color: #756d6d !important;
  color: rgba(117, 109, 109, 0.77) !important;
  background-color: #ffffff !important;
  background-color: white !important;
}
.menu .menu-footer-mobile-user {
  cursor: pointer;
  background-color: #756d6d;
  background-color: rgba(117, 109, 109, 0.77);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.menu .menu-footer-mobile-user:hover, .menu .menu-footer-mobile-user:active, .menu .menu-footer-mobile-user:focus {
  color: #756d6d !important;
  color: #756d6d !important;
  background-color: #ffffff !important;
  background-color: white !important;
}
.menu .menu-footer-mobile-location, .menu .menu-footer-mobile-user {
  padding: 10px;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  color: white;
}
.menu .menu-footer-mobile-location-subnav {
  background-color: #f6f6f6;
  background-color: #f6f6f6;
  color: #000000;
  color: black;
}
.menu .menu-footer-mobile-location-subnav:hover, .menu .menu-footer-mobile-location-subnav:active, .menu .menu-footer-mobile-location-subnav:focus {
  color: #f6f6f6 !important;
  color: #f6f6f6 !important;
  background-color: #000000 !important;
  background-color: black !important;
}
@media (min-width: 768px) {
  .menu .menu-footer {
    bottom: 1.25rem;
    max-width: 1400px !important;
  }
}
.menu .menu-footer-disclaimer {
  text-align: right;
  font-size: 0.7rem;
  color: #162b32;
  color: #162b32;
}
.menu .menu-footer-disclaimer-color {
  color: #ffffff;
  color: white;
}
@media (min-width: 768px) {
  .menu {
    background-size: 260px;
    background-color: #ffffff;
  }
}

.menu-open {
  visibility: visible;
  opacity: 1;
}

.menu-bg-color {
  background-color: #ffffff !important;
  background-color: white !important;
}

.navigation-bar-hamburger {
  position: absolute;
  right: 40px;
  top: 20px;
  display: none;
  display: block;
}
@media (min-width: 768px) {
  .navigation-bar-hamburger {
    display: none;
  }
}

.navigation-bar-hamburger-light .hamburger-react {
  border-radius: 50%;
  background-color: #ffffff;
  background-color: white;
}
.navigation-bar-hamburger-light .hamburger-react div {
  background: #bebebe !important;
}

.navigation-bar-hamburger-dark .hamburger-react {
  border-radius: 50%;
  background-color: #aaaaaa;
  background-color: #aaaaaa;
}
.navigation-bar-hamburger-dark .hamburger-react div {
  background: #ffffff !important;
}/*# sourceMappingURL=Menu.css.map */
.error-message {
  z-index: 3000;
  float: right;
  display: flex;
  position: fixed;
  float: right;
  right: 0.938rem;
  top: 4.5rem;
}
.error-message .error-message-header {
  background-color: #cc3300;
  background-color: #cc3300;
  color: white;
}/*# sourceMappingURL=ErrorMessage.css.map */
.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 25px;
  padding-left: 40px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 1400px;
  }
}
@media (min-width: 1700px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1700px;
  }
}
/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  display: flex;
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
}

#preloader:before,
#preloader:after {
  content: "";
  background-color: var(--color-secondary);
  position: absolute;
  inset: 0;
  width: 50%;
  height: 100%;
  transition: all 0.3s ease 0s;
  z-index: -1;
}

#preloader:after {
  left: auto;
  right: 0;
}

#preloader .line {
  position: relative;
  overflow: hidden;
  margin: auto;
  width: 1px;
  height: 280px;
  transition: all 0.8s ease 0s;
}

#preloader .line:before {
  content: "";
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 50%;
  width: 1px;
  height: 0%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-animation: lineincrease 1000ms ease-in-out 0s forwards;
          animation: lineincrease 1000ms ease-in-out 0s forwards;
}

#preloader .line:after {
  content: "";
  position: absolute;
  background-color: #999;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  -webkit-animation: linemove 1200ms linear 0s infinite;
          animation: linemove 1200ms linear 0s infinite;
  -webkit-animation-delay: 2000ms;
          animation-delay: 2000ms;
}

#preloader.loaded .line {
  opacity: 0;
  height: 100% !important;
}

#preloader.loaded .line:after {
  opacity: 0;
}

#preloader.loaded:before,
#preloader.loaded:after {
  -webkit-animation: preloaderfinish 300ms ease-in-out 500ms forwards;
          animation: preloaderfinish 300ms ease-in-out 500ms forwards;
}

@-webkit-keyframes lineincrease {
  0% {
    height: 0%;
  }

  100% {
    height: 100%;
  }
}

@keyframes lineincrease {
  0% {
    height: 0%;
  }

  100% {
    height: 100%;
  }
}

@-webkit-keyframes linemove {
  0% {
    -webkit-transform: translateY(200%);
            transform: translateY(200%);
  }

  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

@keyframes linemove {
  0% {
    -webkit-transform: translateY(200%);
            transform: translateY(200%);
  }

  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

@-webkit-keyframes preloaderfinish {
  0% {
    width: 5 0%;
  }

  100% {
    width: 0%;
  }
}

@keyframes preloaderfinish {
  0% {
    width: 5 0%;
  }

  100% {
    width: 0%;
  }
}


.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.313rem 3.125rem;
  font-size: 0.938rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  -webkit-appearance: none;
          appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  text-align: left;
  justify-content: left;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  text-align: left;
  justify-content: left;
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  text-align: left;
  justify-content: left;
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
  text-align: left;
  justify-content: left;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.063rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.063rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 4.688rem;
  height: 4.688rem;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url(/static/media/prev_arrow_icon.e5c5bcc0.svg);
}

.carousel-control-next-icon {
  background-image: url(/static/media/next_arrow_icon.0670c75e.svg);
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.063rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.063rem - 1px);
  border-top-right-radius: calc(0.063rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.063rem - 1px);
  border-bottom-left-radius: calc(0.063rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.063rem - 1px) calc(0.063rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.063rem - 1px) calc(0.063rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.063rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.063rem - 1px);
  border-top-right-radius: calc(0.063rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.063rem - 1px);
  border-bottom-left-radius: calc(0.063rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    -moz-column-gap: 1.25rem;
         grid-column-gap: 1.25rem;
         -webkit-column-gap: 1.25rem;
                 column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

a,
a:hover {
  text-decoration: none;
  color: inherit;
}

.btn {
  text-transform: uppercase;
}

.mt-7 {
  margin-top: 5rem;
}

.ml-7 {
  margin-left: 9rem;
}

.mt-8 {
  margin-top: 7.5rem;
}

.mb-6 {
  margin-bottom: 3.5rem;
}

.mb-8 {
  margin-bottom: 7.5rem;
}

.mt-9 {
  margin-top: 9rem;
}

.mt-10 {
  margin-top: 11.5rem;
}

.mt-11 {
  margin-top: 13rem;
}

.pt-7 {
  padding-top: 5rem;
}

.pt-8 {
  padding-top: 7.5rem;
}

.pt-9 {
  padding-top: 9rem;
}

.pt-10 {
  padding-top: 11.5rem;
}

.pt-11 {
  padding-top: 13rem;
}

.pb-10 {
  padding-bottom: 11.5rem;
}

.category-item-overlay {
  overflow: hidden;
  position: relative;
}
.category-item-overlay:hover .category-item-primary-media-scale {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
}
.category-item-overlay:hover .category-item-overlay-wrapper, .category-item-overlay:hover .category-item-overlay-wrapper-icon {
  opacity: 1;
  filter: alpha(opacity=100);
}
.category-item-overlay:hover .category-item-overlay-wrapper-icon {
  -webkit-transform: translatey(0);
          transform: translatey(0);
  transition-delay: 0.2s;
}
.category-item-overlay .category-item-overlay-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  opacity: 0;
  display: flex;
  align-items: center;
  transition: all 0.4s ease-in-out;
  background-color: #426fad;
  background-color: rgba(66, 111, 173, 0.36);
}
.category-item-overlay .category-item-overlay-wrapper-icon {
  display: inline-block;
  font-size: 2.813rem;
  opacity: 0;
  margin: auto;
  border-radius: 50%;
  padding: 0.313rem;
  filter: alpha(opacity=0);
  transition: all 0.2s ease-in-out;
  color: #53555a;
  color: #53555a;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0.79);
}

.contact-form {
  background-color: #e4e4e4;
  scroll-margin-top: 60px;
  color: #000000;
  color: black;
  text-align: left;
}
.contact-form .contact-form-wrapper-title {
  color: #000000;
  color: rgba(0, 0, 0, 0.76);
}
.contact-form .contact-form-wrapper-subtitle {
  text-align: left;
  font-size: 16px;
  color: #000000;
  color: rgba(0, 0, 0, 0.58);
}
.contact-form .contact-form-wrapper-title, .contact-form .contact-form-wrapper-subtitle {
  text-transform: uppercase;
}

.custom-file-input {
  cursor: pointer;
}

.contact-form-wrapper input::-webkit-input-placeholder, .contact-form-wrapper textarea::-webkit-input-placeholder {
  color: #000000;
  color: rgba(0, 0, 0, 0.51);
}

.contact-form-wrapper input::placeholder, .contact-form-wrapper textarea::placeholder {
  color: #000000;
  color: rgba(0, 0, 0, 0.51);
}
.contact-form-wrapper-required, .contact-form-wrapper-submit, .contact-form-wrapper-submit-success {
  text-transform: uppercase;
}
.contact-form-wrapper-required {
  display: block;
  text-align: right;
  font-size: 0.688rem;
}
.contact-form-wrapper-privacy-policy-col {
  margin-bottom: 20px;
  text-align: center;
}
@media (min-width: 768px) {
  .contact-form-wrapper-privacy-policy-col {
    text-align: left;
  }
}
.contact-form-wrapper-privacy-policy, .contact-form-wrapper-privacy-policy:hover {
  text-decoration: underline;
  color: #ff2a2a;
  color: #ff2a2a;
}
.contact-form-wrapper-submit-col {
  text-align: center;
}
@media (min-width: 768px) {
  .contact-form-wrapper-submit-col {
    text-align: left;
  }
}
.contact-form-wrapper-submit {
  border: 0.063rem solid #535353;
  width: auto !important;
  padding: 0.313rem 50px !important;
  color: #535353;
  color: #535353;
}
.contact-form-wrapper-submit:hover {
  color: #ffffff;
  color: white;
}
.contact-form-wrapper-submit-success {
  font-size: 0.875rem;
}/*# sourceMappingURL=index.css.map */
