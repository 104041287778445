@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Quicksand:wght@300;400&family=Roboto&display=swap');


  .imageSecondPage{
    justify-content: center;
    width: 60%;
    margin-left: 147px;
  }
  

  .gray_row {
    background-color: #f2f2f2;
  }
  
  .white_row {
    background-color: #ffffff;
  }
  
  .content_table{
    width: 80%;
    justify-content: center;
    
  }

.table {    
    justify-content: center;
    align-items: center;
    text-align: left;
}

table {
    width: 100%;
    height: 489px;
    margin-top: 20px;
}

li{
    line-height: 32px;
}

p{
    line-height: 16px;
}

   

  .tableHeader {
    background-color: gray;
    color: white;
  }
